import React from "react";
import "./Landing.css"; 
import { Link } from "react-router-dom"; 

const LandingPage = () => {
  return (
    <div className="landing-container">
      <div className="landing-page">
        <div className="landing-section">
          <div className="landing-button">
            <a
              href="https://api.united-for-literacy-volunteer-portal.com/accounts/salesforce/login/?process=login"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="staff-button">Login Here</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
