import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./Navbar.css";
import NavDropdown from "react-bootstrap/NavDropdown";

function AdminNavbar() {
  return (
    <div className="nav-container">
      <Navbar expand="lg" className=" nav-container">
        <Container fluid>
          <Navbar.Brand href="/">
            <img
              src="https://3nyqxuznpf.us-east-2.awsapprunner.com/_nuxt/header-logo.fecced24.png"
              alt="Logo"
              className="navbar-logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Nav.Link href="/Pages/PostNotification">Post Notifications</Nav.Link>
              <Nav.Link href="/Pages/UnderConstructionAdmin">Post Programs</Nav.Link>
              <Nav.Link href="/Pages/AdminResources">Upload Resources</Nav.Link>
              <NavDropdown title="More" id="navbarScrollingDropdown">
                <NavDropdown.Item href="/Pages/Calendar">
                  Calendar
                </NavDropdown.Item>
                <NavDropdown.Item href="/Pages/Testimonial">
                  Submit Testimonial
                </NavDropdown.Item>
                <NavDropdown.Item href="/Pages/AdminProfile">
                  Profile
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="">
                  Sign Out
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Form className="d-flex">
              <Form.Control
                type="search"
                placeholder="Search"
                className="me-2"
                aria-label="Search"
              />
              <Button variant="outline-success">Search</Button>
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default AdminNavbar;