/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTestimonials = /* GraphQL */ `
  query GetTestimonials($id: ID!) {
    getTestimonials(id: $id) {
      id
      name
      email
      over18
      relationship_to_submitter
      time
      content
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTestimonials = /* GraphQL */ `
  query ListTestimonials(
    $filter: ModelTestimonialsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestimonials(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        over18
        relationship_to_submitter
        time
        content
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUsers = /* GraphQL */ `
  query GetUsers($id: ID!) {
    getUsers(id: $id) {
      id
      uType
      name
      password
      phone
      email
      interests
      experience
      location
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        uType
        name
        password
        phone
        email
        interests
        experience
        location
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
