import React, { useState, useEffect } from "react";
import { Card, Button, Form } from "react-bootstrap";
import AppNavbar from "../GeneralComponent/Navbar";
import "./Dashboard.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
const itemsPerPage = 4;

const DashboardPage = () => {
  const [csrfToken, setCsrfToken] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loggedHours, setLoggedHours] = useState(0);
  const [program, setProgram] = useState("");
  const [startDate, setStartDate] = useState("");
  const [numDaysAttended, setNumDaysAttended] = useState(0);
  const [volunteerHours, setVolunteerHours] = useState(0);
  const [programOfferings, setProgramOfferings] = useState([]);
  const [recordType, setRecordType] = useState("");

  function getCsrfToken() {
    return Cookies.get("csrftoken");
  }
  const handleUpdateHours = async (e) => {
    e.preventDefault();
    const url =
      "https://api.united-for-literacy-volunteer-portal.com/volunteer_hours/";
    const newHours = document.getElementById("hoursInput").value;
    const sumHours = parseInt(loggedHours) + parseInt(newHours);

    const requestBody = {
      Volunteer_Hours__c: newHours,
      Program_Offering__c: program,
      Date_Entered__c: startDate,
      Days_Attended__c: numDaysAttended,
      RecordTypeId: recordType,
    };

    try {
      const response = await fetch(url, {
        headers: {
          enctype: "multipart/form-data",
          "X-CSRFToken": csrfToken,
        },
        method: "POST",
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Failed to update volunteer hours");
      }

      setLoggedHours(sumHours);
      document.getElementById("hoursInput").value = "";
      setProgram("");
      setStartDate("");
      setNumDaysAttended(0);
      setVolunteerHours(0);
      setRecordType("");
    } catch (error) {
      console.error("Error updating volunteer hours:", error);
    }
  };

  const notificationRetrieval = async () => {
    const url =
      "https://api.united-for-literacy-volunteer-portal.com/notifications";

    try {
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to retrieve notifications");
      }

      const notifications = await response.json();
      return notifications;
    } catch (error) {
      throw new Error(`Error retrieving notifications: ${error.message}`);
    }
  };

  const volunteerHoursRetrieval = async () => {
    const url =
      "https://api.united-for-literacy-volunteer-portal.com/volunteer_hours";

    try {
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to retrieve volunteer hours");
      }

      const volunteerHours = await response.json();
      return volunteerHours;
    } catch (error) {
      throw new Error(`Error retrieving volunteer hours: ${error.message}`);
    }
  };

  const programOfferingsRetrieval = async () => {
    const url =
      "https://api.united-for-literacy-volunteer-portal.com/volunteer_hours/program_offerings/";

    try {
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to retrieve program offerings");
      }

      const programOfferings = await response.json();
      return programOfferings;
    } catch (error) {
      throw new Error(`Error retrieving program offerings: ${error.message}`);
    }
  };

  useEffect(() => {
    setCsrfToken(getCsrfToken());
    const fetchData = async () => {
      try {
        const notifications = await notificationRetrieval();
        setNotifications(notifications);
        const programOfferings = await programOfferingsRetrieval();
        setProgramOfferings(programOfferings);
        // until getting volunteer hours is working
        // const volunteerHours = await volunteerHoursRetrieval();
        const volunteerHours = 0;
        setLoggedHours(volunteerHours);
      } catch (error) {
        console.error(
          "Error retrieving notifications/volunteer hours/program offerings:",
          error
        );
      }
    };

    fetchData();
  }, []);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleNotifications =
    currentPage >= 1
      ? notifications.slice(startIndex, endIndex)
      : notifications;

  const totalPages = Math.ceil(notifications.length / itemsPerPage);

  return (
    <div>
      <AppNavbar />
      <div className="main-content">
        <h1>Your Dashboard</h1>
        <div style={{ width: "80%", margin: "0 auto", overflowX: "auto" }}>
          <h2>Notifications</h2>
        </div>
        <div style={{ width: "80%", margin: "0 auto", overflowX: "auto" }}>
          <div style={{ display: "flex" }}>
            {visibleNotifications.map((notification) => (
              <Card
                key={notification.id}
                style={{ width: "18rem", marginRight: "10px" }}
              >
                <Card.Body>
                  <Card.Title>{notification.title}</Card.Title>
                  <Card.Text>{notification.content}</Card.Text>
                </Card.Body>
              </Card>
            ))}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: "10px",
          }}
        >
          <span style={{ margin: "0 10px" }}>
            Page {currentPage} of {Math.max(1, totalPages)}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: "10px",
          }}
        >
          <div style={{ paddingRight: "10px" }}>
            <Button
              variant="primary"
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
          </div>
          <div>
            <Button
              variant="primary"
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </div>
        </div>
        <Row>
          <Col xs={12} md={6}>
            <div
              className="volunteer-hours-section"
              style={{
                backgroundColor: "rgb(86, 140, 86)",
                padding: "10px",
                margin: "10px",
                borderRadius: "10px",
                color: "white",
              }}
            >
              <h2>Volunteer Hours</h2>
              <p>Logged Hours: {loggedHours}</p>
              <Form onSubmit={handleUpdateHours}>
                <Form.Group controlId="recordTypeInput">
                  <Form.Label>Volunteer Type:</Form.Label>
                  <Form.Control
                    as="select"
                    value={recordType}
                    onChange={(e) => setRecordType(e.target.value)}
                    required
                  >
                    <option value="">Select Volunteer Type</option>
                    <option value="0125x000000cch8AAA">Volunteer</option>
                    <option value="0125x000000ccgwAAA">OT Volunteer</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="programInput">
                  <Form.Label>Program:</Form.Label>
                  <Form.Control
                    as="select"
                    value={program}
                    onChange={(e) => setProgram(e.target.value)}
                    required
                  >
                    <option value="">Select Program</option>
                    {Object.entries(programOfferings).map((program) => (
                      <option key={program[0]} value={program[0]}>
                        {program[1]}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="startDateInput">
                  <Form.Label>Start Date:</Form.Label>
                  <Form.Control
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="numDaysAttendedInput">
                  <Form.Label>Number of Days Attended:</Form.Label>
                  <Form.Control
                    type="number"
                    value={numDaysAttended}
                    onChange={(e) => setNumDaysAttended(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="hoursInput">
                  <Form.Label>Additional Hours:</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="# hours"
                    value={volunteerHours}
                    onChange={(e) => setVolunteerHours(e.target.value)}
                  />
                </Form.Group>
                <Button type="submit">Update Hours</Button>
              </Form>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div
              style={{
                width: "95%",
                margin: "0 auto",
                overflowX: "auto",
                backgroundColor: "rgb(86, 140, 86)",
                padding: "10px",
                margin: "10px",
                borderRadius: "10px",
                color: "white",
              }}
            >
              <button>Invite a Friend</button>
              <Link to="/Pages/Testimonial">
                <button>Share your Testimonial with Us</button>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DashboardPage;
