import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../GeneralComponent/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

function Resources() {
  const [volunteerResources, setVolunteerResources] = useState([]);
  const [teachingResources, setTeachingResources] = useState([]);

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const volunteerResponse = await fetch(
          "https://api.united-for-literacy-volunteer-portal.com/teaching/get_all_teaching_materials",
          {
            method: "GET",
            credentials: "include",
          }
        );
        const volunteerData = await volunteerResponse.json();
        setVolunteerResources(volunteerData);

        const teachingResponse = await fetch(
          "https://api.united-for-literacy-volunteer-portal.com/tutoring/get_resources",
          {
            method: "GET",
            credentials: "include",
          }
        );
        const teachingData = await teachingResponse.json();
        setTeachingResources(teachingData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchResources();
  }, []);

  return (
    <div style={{ paddingBottom: "50px" }}>
      <Navbar />
      <h1 className="pt-3">Volunteer Resources</h1>
      <hr />
      <Row className="justify-content-center">
        {Object.entries(volunteerResources).map(([key, value]) => (
          <Col lg={4} md={6} sm={12} key={value}>
            <div
              className="resource-block"
              style={{
                backgroundColor: "rgb(86, 140, 86)",
                padding: "10px",
                margin: "10px",
                borderRadius: "10px",
                color: "white",
                overflowWrap: "break-word",
              }}
            >
              <h2>{value}</h2>
              <div className="resource-download">
                <p>
                  <a
                    href={key}
                    download
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {key}
                  </a>
                </p>
                <div className="justify-content-center text-center">
                  <a
                    href={key}
                    className="justify-content-center text-center"
                    download
                  >
                    <i className="fas fa-download"></i>
                  </a>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
      <h1>Teaching Resources</h1>
      <hr />
      <Row className="justify-content-center">
        {Object.entries(teachingResources).map(([key, value]) => (
          <Col lg={4} md={6} sm={12} key={value}>
            <div
              className="resource-block"
              style={{
                backgroundColor: "rgb(86, 140, 86)",
                padding: "10px",
                margin: "10px",
                borderRadius: "10px",
                color: "white",
                overflowWrap: "break-word",
              }}
            >
              <h2>{value}</h2>
              <div className="resource-download">
                <p>
                  <a
                    href={key}
                    download
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {key}
                  </a>
                </p>
                <div className="justify-content-center text-center">
                  <a
                    href={key}
                    className="justify-content-center text-center"
                    download
                  >
                    <i className="fas fa-download"></i>
                  </a>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default Resources;
