import React, { useState, useEffect } from "react";
import AdminNavbar from "../GeneralComponent/AdminNavbar";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "../App.css";
import Cookies from 'js-cookie';

const NotificationForm = () => {
  function getCsrfToken() {
    return Cookies.get('csrftoken'); 
  }
  const [csrfToken, setCsrfToken] = useState(null);
  const [formData, setFormData] = useState({
    title: "",
    body: "",
    location: "Arviat",
  });

  useEffect(() => {
    setCsrfToken(getCsrfToken());
  }, []);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const postNotification = async (notificationData) => {
    const url = 'https://api.united-for-literacy-volunteer-portal.com/notifications/send/'; 
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken
        },
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(notificationData),
      });
  
      if (!response.ok) {
        throw new Error('Failed to submit notification');
      }
  
    } catch (error) {
      throw new Error(`Error submitting notification: ${error.message}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await postNotification(formData);

      alert("Notification submitted successfully!");
      setFormData({
        title: "",
        body: "",
        location: "",
      });

    } catch (error) {
      console.error('Error submitting notification:', error);
      alert("Notification submission failed.");
    }
  };

  return (
    <div>
      <AdminNavbar />
      <Container className="main-content">
        <h1>Submit a Notification</h1>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="title">
              <Form.Label>
                <strong>Title</strong>
              </Form.Label>
              <Form.Control
                type="text"
                name="title"
                placeholder="Enter title"
                value={formData.title}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="body">
              <Form.Label>
                <strong>Description</strong>
              </Form.Label>
              <Form.Control
                as="textarea"
                name="body"
                placeholder="Enter description"
                value={formData.body}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
          <Form.Group as={Col} controlId="location">
            <Form.Label>
              <strong>Location</strong>
            </Form.Label>
            <Form.Control
              as="select"
              name="location"
              value={formData.location}
              onChange={handleChange}
            >
              <option value="Arviat">Arviat</option>
              <option value="Cambridge Bay">Cambridge Bay</option>
              <option value="Edmonton">Edmonton</option>
              <option value="Fredericton">Fredericton</option>
              <option value="Geraldton/Nakina/Aroland">Geraldton/Nakina/Aroland</option>
              <option value="Grande Prairie">Grande Prairie</option>
              <option value="Guelph">Guelph</option>
              <option value="Halifax">Halifax</option>
              <option value="Hamilton">Hamilton</option>
              <option value="Inuvik">Inuvik</option>
              <option value="Iqaluit">Iqaluit</option>
              <option value="Kingston">Kingston</option>
              <option value="Kitchener - Waterloo">Kitchener - Waterloo</option>
              <option value="Leamington">Leamington</option>
              <option value="Lethbridge">Lethbridge</option>
              <option value="London">London</option>
              <option value="Longlac/Long Lake 58/Ginoogaming">Longlac/Long Lake 58/Ginoogaming</option>
              <option value="Montreal">Montreal</option>
              <option value="North Bay">North Bay</option>
              <option value="Ottawa">Ottawa</option>
              <option value="Preston Township">Preston Township</option>
              <option value="Quebec City">Quebec City</option>
              <option value="Rankin Inlet">Rankin Inlet</option>
              <option value="Regina">Regina</option>
              <option value="Saskatoon">Saskatoon</option>
              <option value="Scarborough">Scarborough</option>
              <option value="Sherbrooke">Sherbrooke</option>
              <option value="Sudbury">Sudbury</option>
              <option value="Thunder Bay">Thunder Bay</option>
              <option value="Toronto">Toronto</option>
              <option value="Vancouver & Lower Mainland">Vancouver & Lower Mainland</option>
              <option value="Vancouver Island">Vancouver Island</option>
              <option value="Whitehorse">Whitehorse</option>
              <option value="Winnipeg">Winnipeg</option>
            </Form.Control>
          </Form.Group>
        </Row>
          <Row className="button-row">
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default NotificationForm;
