import React from "react";
import "./Landing.css"; 
import AdminNavbar from "../GeneralComponent/AdminNavbar";



const UnderConstructionAdmin = () => {
  return (
    <div>
        <AdminNavbar/>
        <h1>This page is coming soon</h1>
    </div>
  );
};

export default UnderConstructionAdmin;
