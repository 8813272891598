  import React, { useState, useEffect } from "react";
  import AppNavbar from "../GeneralComponent/Navbar";
  import "bootstrap/dist/css/bootstrap.min.css";
  import Row from "react-bootstrap/Row";
  import Col from "react-bootstrap/Col";
  import "../App.css";
  import "./Testimonial.css";
  import Form from "react-bootstrap/Form";
  import Button from "react-bootstrap/Button";
  import Cookies from 'js-cookie';


  const TestimonialForm = () => {

    function getCsrfToken() {
      return Cookies.get('csrftoken'); 
    }
    const [csrfToken, setCsrfToken] = useState(null);

    const [formData, setFormData] = useState({
      Long_Story__c: "",
      Program_Offering__c: "",
      Source__c: "",
      Topics__c: "",
      Date_Recorded__c: "",
    });

    const [programOfferings, setProgramOfferings] = useState([]);

    const handleChange = (e) => {
      const { name, value, type, checked } = e.target;
      setFormData({
        ...formData,
        [name]: type === "checkbox" ? checked : value,
      });
    };

    useEffect(() => {
      setCsrfToken(getCsrfToken());
      console.log(`token is ${csrfToken}`)
      const fetchProgramOfferings = async () => {
        try {
          const response = await fetch('https://api.united-for-literacy-volunteer-portal.com/testimonials/program_offerings/', {
            method: 'GET',
            credentials: 'include'
          });
          const data = await response.json();
          setProgramOfferings(data);
          console.log("done");
        } catch (error) {
          console.error("Error fetching program offerings:", error);
        }
      };
      fetchProgramOfferings();
      setTimeout(()=>console.log(programOfferings), 1000)
    }, []);

    const testData = {
      "a26Au00000008tdIAA" : "Literacy and Basic Skills", 
      "a26Au00000008vFIAQ" : "Sapotaweyak Cree Summer Camp", 
      "a26Au00000008wrIAA" : "Wonder in the Park Reading Tent", 
      "a26Au0000000BI1IAM" : "Neighbourhood Group Brainfit", 
      "a26Au0000004QS9IAM" : "test"
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      const date = new Date();
      const dateRecorded = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
      console.log(`date recorded: ${dateRecorded}`);
      console.log(formData);
      const updatedFormData = {
        ...formData,
        Date_Recorded__c: dateRecorded,
      };

      const postRequest = {
        body: JSON.stringify(updatedFormData),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken
        },
        method: 'POST',
        credentials: 'include'
      };
      console.log(csrfToken);
      try {
        const response = await fetch('https://api.united-for-literacy-volunteer-portal.com/testimonials/', postRequest);
        if (response.status === 201) {
          alert("Testimonial submitted successfully!");
          setFormData({
            Long_Story__c: "",
            Program_Offering__c: "",
            Source__c: "",
            Topics__c: "",
            Date_Recorded__c: "",
          });
        } else {
          alert("Testimonial submission failed.");
          console.log(formData)
        }
      } catch (error) {
        alert("Testimonial submission failed.")
        console.log(formData)
        console.error("Error submitting testimonial:", error);
      }
    };

    const renderDropdown = (fieldName, options) => (
      <Form.Control as="select" name={fieldName} value={formData[fieldName]} onChange={handleChange} required>
        <option value="" disabled>{`Select ${fieldName.replace(/__c/g, '').replace(/_/g, ' ')}`}</option>
        {Object.entries(options).map((i) => (
          <option key={i[1]} value={i[1]}>
            {i[1]}
          </option>
        ))}
      </Form.Control>
    );

    return (
      <div>
        <AppNavbar />
        <div className="main-content">
          <h1>Submit a Testimonial</h1>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="programOffering">
                <Form.Label>
                  <strong>Program Offering</strong>
                </Form.Label>
                <Form.Control as="select" name={"Program_Offering__c"} value={formData["Program_Offering__c"]} onChange={handleChange} required>
                  <option value="" disabled>{`Select ${"Program_Offering__c".replace(/__c/g, '').replace(/_/g, ' ')}`}</option>
                  {Object.entries(programOfferings).map((item) => (
                    <option key={item[0]} value={item[0]}>
                      {item[1]}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} controlId="relationship">
                <Form.Label>
                  <strong>Relationship to UL</strong>
                </Form.Label>
                {renderDropdown("Source__c", ["Volunteer", "Learner", "Parent", "Community Partner", "Educator", "Camp Admin", "Elder", "Other"])}
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} controlId="testimonial">
                <Form.Label>
                  <strong>Testimonial</strong>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="Long_Story__c"
                  placeholder="Enter your testimonial"
                  value={formData.Long_Story__c}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} controlId="topics">
                <Form.Label>
                  <strong>Topics</strong>
                </Form.Label>
                {renderDropdown("Topics__c", ["Partner/Community", "Community Leader Thoughts", "Community Member Thoughts", "Educator Feedback", "Innovations", "Challenges"])}
              </Form.Group>
            </Row>

            <Row className="button-row">
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Row>
          </Form>
        </div>
      </div>
    );
  };
  export default TestimonialForm;
