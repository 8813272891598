import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from './UserContext';

const ProtectedRoute = ({ element, roles }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await fetch('https://api.united-for-literacy-volunteer-portal.com/accounts/user/', 
        {credentials: 'include'});
        const data1 = await response.json();
        setData(data1);
      } catch (error) {
        console.error('Authentication check failed', error);
        // Handle error, maybe set user to unauthenticated
      } finally {
        setIsLoading(false);
      }
    };
    checkAuthentication();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  
  console.log(data);
  console.log(roles);
  const isAuthorized = data && data.authenticated && roles.includes(data.UserRoleId);

  return isAuthorized ? element : <Navigate to="/" replace />;
};

export default ProtectedRoute;
