import { API } from "aws-amplify";
import { createTestimonials } from "./../graphql/mutations.js";
import { listTestimonials } from "./../graphql/queries.js";

export const testimonialSubmission = async (testimonial) => {
  try {
    const result = await API.graphql({
      query: createTestimonials,
      variables: {
        input: {
          name: testimonial.name,
          email: testimonial.email,
          over18: testimonial.isUnder18,
          relationship_to_submitter: testimonial.relationship,
          time: new Date().toISOString(),
          content: testimonial.testimonial,
        },
      },
    });
    console.log("Successfully submitted testimonial.");
    console.log(result);
    return 1;
  } catch (err) {
    console.log("Error submitting testimonial: ", err);
    return 0;
  }
};

export const testimonialRetrieval = async function getData() {
  try {
    const result = await API.graphql({
      query: listTestimonials,
    });
    console.log("Successfully retrieved testimonials.");
    console.log(result);
    return result.data.listTestimonials.items;
  } catch (err) {
    console.log("Error retrieving testimonials: ", err);
    return 0;
  }
}
