import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import AppNavbar from '../GeneralComponent/Navbar';
import './Calendar.css';
import Cookies from "js-cookie";
import { Form, Row, Col, Button } from 'react-bootstrap';
import { Alert } from 'react-bootstrap';

const localizer = momentLocalizer(moment);

const CustomCalendar = () => {
  function getCsrfToken() {
    return Cookies.get("csrftoken"); 
  }
  const [csrfToken, setCsrfToken] = useState(null);
  const [events, setEvents] = useState([]);
  const [subject, setSubject] = useState('');
  const [duration, setDuration] = useState('');
  const [dateTime, setDateTime] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');


  const fetchEvents = () => {
    fetch('https://api.united-for-literacy-volunteer-portal.com/calendar_events/get_events/', {
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken
      },
      method: "GET",
      credentials: "include",
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        const transformedEvents = Object.keys(data).map(key => {
          const event = data[key];
          return {
            id: key,
            title: event[0],
            start: new Date(event[2]),
            end: new Date(moment(event[2]).add(event[1], 'minutes').toDate())
          };
        });
        setEvents(transformedEvents);
      })
      .catch(error => {
        console.error('Failed to fetch events:', error);
      });
  };

  useEffect(() => {
    setCsrfToken(getCsrfToken());
    fetchEvents();
  }, [fetchEvents]);

  const handleAddEvent = () => {
    const eventData = {
      Subject: subject,
      DurationInMinutes: duration,
      ActivityDateTime: dateTime + ':00.000'
    };

    fetch('https://api.united-for-literacy-volunteer-portal.com/calendar_events/add_event/', {
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken
      },
      method: "POST",
      credentials: "include",
      body: JSON.stringify(eventData)
    })
    .then(response => {
      if (response.status === 201) {
        console.log("success");
        setAlertMessage('New event added successfully!');
        setShowAlert(true);
        fetchEvents(); 
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    })
    .catch(error => {
      console.error('Failed to add event:', error);
      setAlertMessage('Failed to add event.');
      setShowAlert(true);
    });
  };

  return (
    <div>
      <AppNavbar />
      <div className="main-content">
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 700 }}
      />
      {showAlert && <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
        {alertMessage}
      </Alert>}
      
      <Form className="form2" style={{ margin: '20px' }}>
        <Row className="row2">
          <Col md={12} lg={true}>
          <Form.Select value={subject} onChange={(e) => setSubject(e.target.value)}>
              <option value="Meeting">Meeting</option>
              <option value="Fundraising Event">Fundraising Event</option>
              <option value="Media Coverage">Media Coverage</option>
              <option value="Other">Other</option>
            </Form.Select>
          </Col>
          <Col md={12} lg={true}>
          <Form.Control
              type="number"
              placeholder="Duration in Minutes"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            />
          </Col>
          <Col md={12} lg={true}>
          <Form.Control
              type="datetime-local"
              value={dateTime}
              onChange={(e) => setDateTime(e.target.value)}
            />
          </Col>
          <Col md={12} lg={true}>
            <Button variant="primary" onClick={handleAddEvent}>Add Event</Button>
          </Col>
        </Row>
      </Form>
    </div>
    </div>
  );
};

export default CustomCalendar;
