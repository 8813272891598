// LoginSuccess.js
import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../UserContext';

const LoginSuccess = () => {
    const { setUser } = useContext(UserContext);
    const navigate = useNavigate();
  
    useEffect(() => {
      const fetchUserData = async () => {
        try {
          const response = await fetch('https://api.united-for-literacy-volunteer-portal.com/accounts/user/',
          { credentials: "include" });
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const userData = await response.json();
          console.log("wtf1");
          console.log(userData);
          return userData;
        } catch (error) {
          console.error('Error fetching data:', error);
          throw error;
        }
      };
      console.log("wtf3");
      fetchUserData()
        .then(data => {
          if (data && data.authenticated !== false) {
            setUser(data); // Set user data in context
            console.log("wtf2")
            console.log(data)
            // Redirect based on user role
            if (data.UserRoleId === 'Volunteer') {
              navigate('/pages/profile');
            } else {
              navigate('/pages/adminProfile');
            }
          } else {
            // Handle unauthenticated or error state
            console.error('User is not authenticated');
            // Redirect to login page or show error message
            navigate('/');
          }
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
          // Handle error state
          navigate('/error');
        });
    }, [setUser, navigate]);

  return <div>Loading...</div>;
};

export default LoginSuccess;
