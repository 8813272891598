import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./Profile.css";
import AppNavbar from "../GeneralComponent/Navbar";

const Profile = () => {
  const [profileData, setProfileData] = useState({
    name: "Simon",
    userId: "1234567",
    position: "Volunteer",
    email: "simonjeremy@gmail.com",
    phoneNumber: "+1 647 015 2846",
    primaryLocation: "Toronto",
  });

  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState();

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch("/user/profile/update", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(profileData),
    })
      .then((response) => response.json())
      .then((data) => {
        setProfileData(data);
        setIsEditing(false);
      })
      .catch((error) => console.error("Error updating profile:", error));
  };

  useEffect(() => {
    fetch("https://api.united-for-literacy-volunteer-portal.com/accounts/user/", {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 401) {
          return { authenticated: false };
        }
        return response.json();
      })
      .then((data) => {
        if (data.authenticated) {
          setProfileData(data);
        } else {
          console.log("User is not authenticated");
        }
      })
      .catch((error) =>
        console.error("Error fetching profile data:", error)
      );
  }, []);

  return (
    <div>
      <AppNavbar />
      <div className="content-wrap">
        {isEditing ? (
          <div className="main-content">
            <h1>My Profile</h1>
            <Form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="name">
                  <Form.Label>
                    <strong>Name</strong>
                  </Form.Label>
                  <Form.Control
                    type="name"
                    placeholder="Enter name"
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="userId">
                  <Form.Label>
                    <strong>UserID</strong>
                  </Form.Label>
                  <Form.Control
                    type="userId"
                    placeholder="Enter UserID"
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="position">
                  <Form.Label>
                    <strong>Position</strong>
                  </Form.Label>
                  <Form.Control
                    type="position"
                    placeholder="Enter your position at United for Literacy"
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="email">
                  <Form.Label>
                    <strong>Email</strong>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="phoneNumber">
                  <Form.Label>
                    <strong>Phone Number</strong>
                  </Form.Label>
                  <Form.Control
                    type="phoneNumber"
                    placeholder="Enter your phone number"
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="primaryLocation">
                  <Form.Label>
                    <strong>Primary Location</strong>
                  </Form.Label>
                  <Form.Control
                    type="primaryLocation"
                    placeholder="Enter your primary location"
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Row>
              <Row className="button-row">
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Row>
            </Form>
          </div>
        ) : (
          <div className="main-content">
            <h1>My Profile</h1>
            <Form onSubmit={handleEditClick}>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="name">
                  <Form.Label>
                    <strong>Name</strong>
                  </Form.Label>
                  <Form.Control
                    type="name"
                    placeholder="Simon"
                    onChange={handleChange}
                    disabled
                    className="input-box"
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="userId">
                  <Form.Label>
                    <strong>UserID</strong>
                  </Form.Label>
                  <Form.Control
                    type="userId"
                    placeholder="123456789"
                    onChange={handleChange}
                    disabled
                    className="input-box"
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="position">
                  <Form.Label>
                    <strong>Position</strong>
                  </Form.Label>
                  <Form.Control
                    type="position"
                    placeholder="Volunteer"
                    onChange={handleChange}
                    disabled
                    className="input-box"
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="email">
                  <Form.Label>
                    <strong>Email</strong>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="simonjeremy@gmail.com"
                    onChange={handleChange}
                    disabled
                    className="input-box"
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="phoneNumber">
                  <Form.Label>
                    <strong>Phone Number</strong>
                  </Form.Label>
                  <Form.Control
                    type="phoneNumber"
                    placeholder="+1 234 567 8910"
                    onChange={handleChange}
                    disabled
                    className="input-box"
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="primaryLocation">
                  <Form.Label>
                    <strong>Primary Location</strong>
                  </Form.Label>
                  <Form.Control
                    type="primaryLocation"
                    placeholder="Toronto"
                    onChange={handleChange}
                    disabled
                    className="input-box"
                  />
                </Form.Group>
              </Row>
              <Row className="button-row">
                <Button variant="primary" type="submit">
                  Edit Profile
                </Button>
              </Row>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
