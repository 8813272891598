import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { testimonialRetrieval } from "../backendUtils/testimonialSubmission.js";
import "./ViewTestimonials.css";
import AdminNavbar from "../GeneralComponent/AdminNavbar";

function ViewTestimonials() {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    testimonialRetrieval().then((testimonials) => {
      setTestimonials(testimonials);
    });
  }, []);

  return (
    <div className="page-container">
      <AdminNavbar />
      <div className="main-content">
        <div>
          <h1 className="test">Testimonials</h1>
            {testimonials.map((testimonial, i) => (
                <div key={i} className="testimonial">
                <h3>{testimonial.name}, {testimonial.relationship_to_submitter} with United for Literacy</h3>
                <p>{testimonial.content}</p>
                </div>
            ))}
            <a href="/Pages/Testimonial">
          <Button variant="primary" type="submit">
             Submit a New Testimonial
            </Button>
            </a>
        </div>
      </div>
    </div>
  );
}

export default ViewTestimonials;
