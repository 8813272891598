import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoutes";
import { UserProvider } from './UserContext';


import LoginSuccess from "./Pages/LoginSuccess";
import Profile from "./Pages/Profile";
import Landing from "./Pages/Landing";
import DashboardPage from "./Pages/Dashboard";
import PostNotification from "./Pages/PostNotification";
import AdminProfile from "./Pages/AdminProfile";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./GeneralComponent/Footer";
import TestimonialForm from "./Pages/Testimonial";
import ViewTestimonials from "./Pages/ViewTestimonials";
import AdminResources from "./Pages/AdminResources";
import Resources from "./Pages/Resources";
import Calendar from "./Pages/Calendar";
import UnderConstructionAdmin from "./Pages/UnderConstructionAdmin";
import UnderConstruction from "./Pages/UnderConstruction";
import "./App.css";
import { Amplify } from 'aws-amplify';
// NOTE: The below settings are included SOLELY for the purpose of testing the
// TestimonialForm component locally (i.e. if you run this app locally, this is
// essential to ensure that the code is pushed to the database. This is not needed
// for the deployed application to function and will be removed for production builds).
const awsmobile = {
  "aws_project_region": "us-east-2",
  "aws_appsync_graphqlEndpoint": "https://flrhphf2jzdnpcspgy7danzj5y.appsync-api.us-east-2.amazonaws.com/graphql",
  "aws_appsync_region": "us-east-2",
  "aws_appsync_authenticationType": "API_KEY",
  "aws_appsync_apiKey": "da2-rtyttol4zndtzk3k73weiq7pnm"
};
Amplify.configure(awsmobile);

function App() {
  return (
    <UserProvider>
      <div className="page-container">
        <Router>
          <div>
            <Routes>
              <Route path="" element={<Landing />} />

              {/* Routes accessible by all authenticated users */}
              <Route
                path="/Login/Success"
                element={
                  <LoginSuccess/>
                }
              />
              <Route
                path="/Pages/Dashboard"
                element={
                  <ProtectedRoute
                    roles={["System Administrator", "Program Administrator", "Regional Staff", "Volunteer"]}
                    element={<DashboardPage />}
                  />
                }
              />
              <Route
                path="/Pages/Profile"
                element={
                  <ProtectedRoute
                    roles={["System Administrator", "Program Administrator", "Regional Staff", "Volunteer"]}
                    element={<Profile />}
                  />
                }
              />
              <Route
                path="/Pages/Resources"
                element={
                  <ProtectedRoute
                    roles={["System Administrator", "Program Administrator", "Regional Staff", "Volunteer"]}
                    element={<Resources />}
                  />
                }
              />
              <Route
                path="/Pages/Calendar"
                element={
                  <ProtectedRoute
                    roles={["System Administrator", "Program Administrator", "Regional Staff", "Volunteer"]}
                    element={<Calendar />}
                  />
                }
              />
              <Route
                path="/Pages/ViewTestimonials"
                element={
                  <ProtectedRoute
                    roles={["System Administrator", "Program Administrator", "Regional Staff", "Volunteer"]}
                    element={<ViewTestimonials />}
                  />
                }
              />
              <Route
                path="/Pages/Testimonial"
                element={
                  <ProtectedRoute
                    roles={["System Administrator", "Program Administrator", "Regional Staff", "Volunteer"]}
                    element={<TestimonialForm />}
                  />
                }
              />
              <Route
                path="/Pages/UnderConstruction"
                element={
                  <ProtectedRoute
                    roles={["System Administrator", "Program Administrator", "Regional Staff", "Volunteer"]}
                    element={<UnderConstruction />}
                  />
                }
              />

              {/* Routes restricted from Volunteers */}
              <Route
                path="/Pages/AdminProfile"
                element={
                  <ProtectedRoute
                    roles={["System Administrator", "Program Administrator", "Regional Staff"]}
                    element={<AdminProfile />}
                  />
                }
              />
              <Route
                path="/Pages/PostNotification"
                element={
                  <ProtectedRoute
                    roles={["System Administrator", "Program Administrator", "Regional Staff"]}
                    element={<PostNotification />}
                  />
                }
              />
              <Route
                path="/Pages/AdminResources"
                element={
                  <ProtectedRoute
                    roles={["System Administrator", "Program Administrator", "Regional Staff"]}
                    element={<AdminResources />}
                  />
                }
              />
              <Route
                path="/Pages/UnderConstructionAdmin"
                element={
                  <ProtectedRoute
                    roles={["System Administrator", "Program Administrator", "Regional Staff"]}
                    element={<UnderConstructionAdmin />}
                  />
                }
              />

            </Routes>
          </div>
        </Router>
        <Footer />
      </div>
    </UserProvider>
  );
}

export default App;