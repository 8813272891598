import React from "react";
import "./Landing.css"; 
import AppNavbar from '../GeneralComponent/Navbar'


const UnderConstruction = () => {
  return (
    <div>
        <AppNavbar/>
        <h1>This page is coming soon</h1>
    </div>
  );
};

export default UnderConstruction;
