import React, { useState, useEffect } from "react";
import AdminNavbar from "../GeneralComponent/AdminNavbar";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Cookies from "js-cookie";
function AdminResources() {
  function getCsrfToken() {
    return Cookies.get("csrftoken");
  }
  const [csrfToken, setCsrfToken] = useState(null);
  const [volunteerResources, setVolunteerResources] = useState([]);
  const [teachingResources, setTeachingResources] = useState([]);
  const [newVolunteerResource, setNewVolunteerResource] = useState({
    Title: "",
    ContentLocation: "S",
    File: "",
  });
  const [newTeachingResource, setNewTeachingResource] = useState({
    Title: "",
    ContentLocation: "S",
    File: "",
  });

  useEffect(() => {
    setCsrfToken(getCsrfToken());
    const fetchResources = async () => {
      try {
        const volunteerResponse = await fetch(
          "https://api.united-for-literacy-volunteer-portal.com/teaching/get_all_teaching_materials",
          {
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrfToken,
            },
            method: "GET",
            credentials: "include",
          }
        );
        const volunteerData = await volunteerResponse.json();
        setVolunteerResources(volunteerData);
        const teachingResponse = await fetch(
          "https://api.united-for-literacy-volunteer-portal.com/tutoring/get_resources",
          {
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrfToken,
            },
            method: "GET",
            credentials: "include",
          }
        );
        console.log(teachingResponse);
        console.log(volunteerResponse);

        const teachingData = await teachingResponse.json();
        setTeachingResources(teachingData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchResources();
  }, [csrfToken]);

  const handleVolunteerResourceDelete = (id) => {
    fetch(
      `https://api.united-for-literacy-volunteer-portal.com/tutoring/delete/${id}`,
      {
        method: "DELETE",
        credentials: "include",
      }
    )
      .then((res) => {
        if (res.ok) {
          setVolunteerResources(
            volunteerResources.filter((resource) => resource.id !== id)
          );
        } else {
          console.log("Error deleting volunteer resource");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTeachingResourceDelete = (id) => {
    fetch(
      `https://api.united-for-literacy-volunteer-portal.com/teaching/delete/${id}`,
      {
        method: "DELETE",
        credentials: "include",
      }
    )
      .then((res) => {
        if (res.ok) {
          setTeachingResources(
            teachingResources.filter((resource) => resource.id !== id)
          );
        } else {
          console.log("Error deleting teaching resource");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleVolunteerResourceAdd = async () => {
    console.log(newVolunteerResource);
    console.log(JSON.stringify(newVolunteerResource));
    const formData = new FormData();
    formData.append("Title", newVolunteerResource.Title);
    formData.append("ContentLocation", newVolunteerResource.ContentLocation);
    formData.append("file", newVolunteerResource.File);

    await fetch(
      "https://api.united-for-literacy-volunteer-portal.com/teaching/upload_teaching_material",
      {
        headers: {
          enctype: "multipart/form-data",
          "X-CSRFToken": csrfToken,
        },
        method: "POST",
        body: formData,
        credentials: "include",
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          console.log("Error adding volunteer resource");
        }
      })
      .then((data) => {
        setNewVolunteerResource({
          Title: "",
          ContentLocation: "S",
          File: "",
        }); // reset form
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTeachingResourceAdd = async () => {
    console.log(newTeachingResource);
    console.log(JSON.stringify(newTeachingResource));
    const formData = new FormData();
    formData.append("Title", newTeachingResource.Title);
    formData.append("ContentLocation", newTeachingResource.ContentLocation);
    formData.append("file", newTeachingResource.File);

    await fetch(
      "https://api.united-for-literacy-volunteer-portal.com/tutoring/upload_resource/",
      {
        headers: {
          enctype: "multipart/form-data",
          "X-CSRFToken": csrfToken,
        },
        method: "POST",
        body: formData,
        credentials: "include",
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          console.log("Error adding teaching resource");
        }
      })
      .then((data) => {
        setTeachingResources([...teachingResources, data]);
        setNewTeachingResource({
          Title: "",
          ContentLocation: "S",
          File: "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleVolunteerFileChange = (event) => {
    const file = event.target.files[0];
    setNewVolunteerResource({
      ...newVolunteerResource,
      Title: file.name,
      ContentLocation: "S",
      File: file,
    });
  };

  const handleTeachingFileChange = (event) => {
    const file = event.target.files[0];
    setNewTeachingResource({
      ...newTeachingResource,
      Title: file.name,
      ContentLocation: "S",
      File: file,
    });
  };

  return (
    <div style={{ paddingBottom: "70px" }}>
      <AdminNavbar />
      <h1 className="pt-3">Volunteer Resources</h1>
      <hr />
      <div className="resource-container">
        <Row className="justify-content-center">
          {Object.entries(volunteerResources).map(([key, value]) => (
            <Col lg={4} md={6} sm={12} key={value}>
              <div
                className="resource-block"
                style={{
                  backgroundColor: "rgb(86, 140, 86)",
                  padding: "10px",
                  margin: "10px",
                  borderRadius: "10px",
                  color: "white",
                  overflowWrap: "break-word",
                }}
              >
                <h2>{value}</h2>
                <div className="resource-download">
                  <p>
                    <a
                      href={key}
                      download
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      {key}
                    </a>
                  </p>
                  <div className="text-center">
                    <a href={key} download>
                      <i className="fas fa-download"></i>
                    </a>
                    <Button
                      variant="danger"
                      onClick={() => handleVolunteerResourceDelete(key)}
                      style={{ width: "75%" }}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <Row className="justify-content-center">
          <Col
            lg={8}
            md={10}
            sm={12}
            style={{
              backgroundColor: "rgb(86, 140, 86)",
              padding: "10px",
              margin: "10px",
              borderRadius: "10px",
              color: "white",
              overflowWrap: "break-word",
            }}
          >
            <div className="resource-block">
              <h2>Add New Resource</h2>
              <div className="resource-download">
                <Form>
                  <Form.Group controlId="formResourceName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      value={newVolunteerResource.Title}
                      onChange={(e) =>
                        setNewVolunteerResource({
                          ...newVolunteerResource,
                          Title: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  <input
                    type="file"
                    id="resource"
                    name="resource"
                    accept="file/pdf, file/txt"
                    onChange={handleVolunteerFileChange}
                  />
                  <div className="text-center">
                    <Button
                      onClick={handleVolunteerResourceAdd}
                      style={{ width: "50%" }}
                      className="justify-content-center"
                    >
                      Add
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <h1>Teaching Resources</h1>
      <hr />
      <div className="resource-container">
        <Row className="justify-content-center">
          {Object.entries(teachingResources).map(([key, value]) => (
            <Col lg={4} md={6} sm={12} key={value}>
              <div
                className="resource-block"
                style={{
                  backgroundColor: "rgb(86, 140, 86)",
                  padding: "10px",
                  margin: "10px",
                  borderRadius: "10px",
                  color: "white",
                  overflowWrap: "break-word",
                }}
              >
                <h2>{value}</h2>
                <div className="resource-download">
                  <p>
                    <a
                      href={key}
                      download
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      {key}
                    </a>
                  </p>
                  <div className="text-center">
                    <a href={key} download>
                      <i className="fas fa-download"></i>
                    </a>
                    <Button
                      variant="danger"
                      onClick={() => handleTeachingResourceDelete(key)}
                      style={{ width: "75%" }}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <Row className="justify-content-center">
          <Col
            lg={8}
            md={10}
            sm={12}
            style={{
              backgroundColor: "rgb(86, 140, 86)",
              padding: "10px",
              margin: "10px",
              borderRadius: "10px",
              color: "white",
              overflowWrap: "break-word",
            }}
          >
            <div className="resource-block">
              <h2>Add New Resource</h2>
              <div className="resource-download">
                <Form>
                  <Form.Group controlId="formResourceName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      value={newTeachingResource.Title}
                      onChange={(e) =>
                        setNewTeachingResource({
                          ...newTeachingResource,
                          Title: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  <input
                    type="file"
                    id="resource"
                    name="resource"
                    accept="file/pdf, file/txt"
                    onChange={handleTeachingFileChange}
                  />
                  <div className="text-center">
                    <Button
                      onClick={handleTeachingResourceAdd}
                      style={{ width: "50%" }}
                    >
                      Add
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default AdminResources;
